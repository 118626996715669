import axios from 'axios'

const estadosTipos = {
    getEstadosTipos: () => axios.get(`ruta-estado-tipo/estados-tipos`),
    crearEstadoTipo: datos =>
        axios.post(`ruta-estado-tipo/estados-tipos`, datos),
    editarEstadoTipo: datos =>
        axios.put(`ruta-estado-tipo/estados-tipos`, datos),
    eliminarEstadoTipo: id =>
        axios.delete(`ruta-estado-tipo/${id}/estados-tipos`),
    actualizarPosicionEstadoTipo: datos =>
        axios.post("ruta-estado-tipo/posicion-estados-tipos", datos),
    cambiarEstadoEstadoTipo: datos =>
        axios.post(`ruta-estado-tipo/estado-estados-tipos`, datos)
};
export default estadosTipos
