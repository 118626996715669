<template>
    <section>
        <div class="row mx-0 border-bottom py-2 pl-5 px-2">
            <div class="col-auto px-0 f-600 text-general">
                Cargando
            </div>
        </div>
        <div class="custom-scroll overflow-auto pt-3 px-4" style="height:calc(100vh - 139px)">
            <p class="f-15 text-general mb-5">
                Sube las imagenes que aparecen cuando las aplicaciones están cargando
            </p>
            <div v-if="show" class="row mx-0">
                <space-image-estados texto="Web" :tipo="81" :data="web" />
                <div class="col-auto px-3" />
                <div class="col-5">
                    <space-image-estados texto="Movil" :tipo="82" :data="movil" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Service from "~/services/configurar/admin/personalizacion"
export default {
    components: {
        spaceImageEstados: () => import('./components/spaceImageEstados')
    },
    data(){
        return {
            show: false,
            certificadoOK: false,
            web: {},
            movil: {},
            fileList: []
        }
    },
    mounted(){
        this.getData();
    },
    methods: {
        async getData(){
            try {
                const {data} = await Service.getWebMovil();
                if(!_.isEmpty(data.web)){
                    this.web = data.web;
                }
                if(!_.isEmpty(data.movil)){
                    this.movil = data.movil;
                }
            } catch(error){
                this.error_catch(error)
            } finally{
                this.show = true;
            }
        },
        async changeFile(file){
            this.file = file.raw;
            let extension = file.raw.type;
            if(this.file != null){
                if(extension != 'image/svg+xml'){
                    this.notificacion('Alerta','El formato de la imagen seleccionada no corresponde con el permitido (SVG)','warning');
                    return this.removeFile();
                }
                try {
                    let model = {
                        tipo: 82,
                        file: this.file,
                    };
                    model = this.crear_formData(model)
                    const {data} = await Service.postMovil(model)
                    this.notificacion('Mensaje', data.mensaje, data.tipo);
                    this.movil = data.data;
                    this.certificadoOK = true;
                    this.removeFile();
                } catch(error){
                    this.error_catch(error);
                }
            }
        },
        removeFile(){
            this.file = null;
        },
    },
}
</script>
<style lang="scss" scoped>
.btn-subir{
    border: 1px dashed #DFE4E8;
    border-radius: 11px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    font-size: 12px;
}

.card-file{
    height: 60px;
    display: flex;
    align-items: center;
    border-radius: 12px;
    background-color: #FFFFFF;
    box-shadow: 0px 2px 4px #00000014;
    .space-file{
        width: 48px;
        height: 100%;
        border-radius: 12px 0px 0px 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #DFE4E8;
    }
    .space-delete{
        width: 60px;
        height: 60px;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        background-color: #FF5158;
    }
}

.shadow-14{
    box-shadow: 0px 2px 4px #00000014;
}
</style>
