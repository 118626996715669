<template>
    <section>
        <div class="row mx-0 p-3 border-bottom">
            <div class="col-auto">
                <p class="f-18 text-general f-600">
                    Operador logístico
                </p>
            </div>
        </div>
        <div class="row mx-0 pl-4 pr-2 mt-5">
            <p class="col-12 px-2 f-15 text-general">
                Indique quién se encargará de la logística de entrega de los pedidos de cada CEDIS.
            </p>
        </div>
        <div class="row mx-0 pl-4 mr-3 mt-5">
            <div class="col-12 ml-2 px-3 border-bottom pb-3 d-flex">
                <div class="col-4" />
                <div class="col f-15 text-general f-500 text-center">
                    Operador interno
                </div>
                <div class="col f-15 text-general f-500 text-center">
                    Operador externo
                </div>
            </div>
        </div>
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 322px)">
            <div v-for="(item,index) in datos" :key="index" class=" row mx-0 pl-4 mr-3 mt-3">
                <div class="col-12 ml-2 px-3 d-middle">
                    <div class="col-4 f-15 text-general f-500">
                        {{ item.nombre }}
                        <i v-show="!item.estado" class="icon-eye-off" />
                    </div>
                    <el-radio-group v-model="item.operacion_logistica" class="fix-radio-operador" @change="onChange(item.id,item.operacion_logistica)">
                        <div class="col pl-4 f-15 text-general f-500 text-center">
                            <el-radio :label="1" class="radio-dark icon-check-radio">
                                <span class="text-white" />
                            </el-radio>
                        </div>
                        <div class="col pl-4 f-15 text-general f-500 text-center">
                            <el-radio :label="2" class="radio-dark icon-check-radio">
                                <span class="text-white" />
                            </el-radio>
                        </div>
                    </el-radio-group>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import cedisOperadorLogistico from '~/services/configurar/admin/cedisOperadorLogistico'
export default {
    data(){
        return {
            tipo: 1,
            datos: {}
        }
    },
    mounted(){
        this.listarCedis();
    },
    methods:{
        async listarCedis(){
            try {
                const { data } = await cedisOperadorLogistico.getCedis();
                this.datos = data
                this.tipo = data.operacion_logistica;
            } catch (e){
                this.error_catch(e)
            }
            
        },
        async onChange(idCedis,operacion_logistica){
            try {
                const { data } = await cedisOperadorLogistico.setCedisOperadorLogistico(idCedis,{operacion_logistica})
                this.notificacion("","Operador actualizado correctamente","success");
            } catch (e){
                this.error_catch(e);
            }
        }
    }
}
</script>