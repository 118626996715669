<template>
    <section class="h-100">
        <div class="row mx-0 px-3 pl-5 f-600 border-bottom py-2">
            Tipo de tiendas
        </div>
        <div class="p-3 f-15 h-90">
            <div class="row mx-0">
                Crea los diferentes tipo de tiendas
            </div>
            <ValidationObserver ref="validacion" v-slot="{ valid }">
                <div class="row mx-0 my-2">
                    <div class="col-auto" style="max-width:100px;">
                        <ValidationProvider v-slot="{errors}" rules="required" name="artículo">
                            <p>Artículo</p>
                            <el-select v-model="model.genero" placeholder="" style="width:68px;">
                                <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                                />
                            </el-select>
                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                        </ValidationProvider>
                    </div>
                    <div class="col-3 px-2">
                        <ValidationProvider v-slot="{errors}" rules="required|max:40" name="tipo">
                            <p class="ml-2">Tipo de tienda</p>
                            <el-input v-model="model.singular" :suffix-icon="false?'icon-attention-alt text-danger':''" maxlength="40" show-word-limit />
                            <span v-show="false" class="text-danger w-100 f-11"> Ya existe una U.M con este nombre </span>
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-3 px-2">
                        <ValidationProvider v-slot="{errors}" rules="required|max:40" name="plural">
                            <p class="ml-2">Plural</p>
                            <el-input v-model="model.plural" maxlength="40" show-word-limit />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-auto mt-3 pt-1">
                        <el-tooltip class="item" effect="light" content="Crear tipo de tienda" placement="bottom">
                            <div class="bg-general cr-pointer d-middle-center br-10" style="width:44px;height:44px;">
                                <i class="icon-plus f-20 text-white" @click="crearRegistro(valid)" />
                            </div>
                        </el-tooltip>
                    </div>
                </div>
            </ValidationObserver>
            <hr />
            <div class="row mx-0 my-2">
                Lista de unidades
            </div>
            <div v-if="listado.length" class="overflow-auto custom-scroll" style="height: calc(100% - 142px);">
                <ValidationObserver ref="validacion" v-slot="{ valid }">
                    <div v-for="(data, idx) in listado" :key="idx" class="row mx-0 my-1">
                        <div class="col-auto" style="max-width:100px;">
                            <ValidationProvider v-slot="{errors}" rules="required" name="artículo">
                                <el-select v-model="data.genero" placeholder="" style="width:68px;" :disabled="editar!=idx">
                                    <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                    />
                                </el-select>
                                <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                            </ValidationProvider>
                        </div>
                        <div class="col-3 px-2">
                            <ValidationProvider v-slot="{errors}" rules="required" name="tipo">
                                <el-input v-model="data.singular" :suffix-icon="false?'icon-attention-alt text-danger':''" :disabled="editar!=idx" />
                                <span v-show="false" class="text-danger w-100 f-11"> Ya existe una U.M con este nombre </span>
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="col-3 px-2">
                            <ValidationProvider v-slot="{errors}" rules="required" name="plural">
                                <el-input v-model="data.plural" :disabled="editar!=idx" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="col-auto px-2">
                            <el-tooltip v-if="editar!=idx" class="item" effect="light" content="Editar" placement="bottom">
                                <div class="d-middle-center btn-edit pt-1 br-10" style="width:44px;height:44px;">
                                    <i class="icon-pencil-outline f-18 cr-pointer" @click="editar=idx" />
                                </div>
                            </el-tooltip>
                            <el-tooltip v-else class="item" effect="light" content="Guardar" placement="bottom">
                                <div class="bg-general2 cr-pointer d-middle-center br-10" style="width:44px;height:44px;" @click="editarTipo(valid,data)">
                                    <i class="icon-ok-circled-outline text-white f-20" />
                                </div>
                            </el-tooltip>
                        </div>
                        <div class="col-auto px-0">
                            <el-tooltip class="item" effect="light" content="Eliminar" placement="bottom">
                                <div class="d-middle-center btn-delete pt-1 br-10" style="width:44px;height:44px;" @click="eliminar(data)">
                                    <i class="icon-trash-empty f-20 cr-pointer" />
                                </div>
                            </el-tooltip>
                        </div>
                        <div class="col-auto pt-2">
                            <el-tooltip class="item" effect="light" content="Leecheros de este tipo" placement="bottom">
                                <div class="br-20 bg-gr-general d-middle text-white justify-content-between" style="min-width:57px;">
                                    <i class="icon-storefront-outline mx-1  f-18" />
                                    <span class="pr-2">{{ data.tiendas_count }}</span>
                                </div>
                            </el-tooltip>
                        </div>
                    </div>
                </ValidationObserver>
            </div>
            <div v-else class="row mx-0 my-3 justify-content-center">
                <div class="my-4 text-center" style="max-width:170px;">
                    No se ha creado ninguna categoría
                </div>
            </div>
        </div>
        <modalEliminar ref="modalEliminar" titulo="Eliminar tipo tienda" mensaje="¿Desea eliminar el tipo de tienda?" @eliminar="EliminarRegistro" />
        <modalEliminarOpcion
        ref="modalEliminarOpcion"
        :listado="listado"
        titulo="Eliminar tipo tienda"
        mensaje="Para eliminarlo debe trasladar las leecheros en cuestión a otro tipo."
        @listar="ListarTipos"
        />
    </section>
</template>

<script>
import TipoTienda from '~/services/configurar/admin/tipoTiendas'
export default {
    components:{
        modalEliminarOpcion: () => import('./eliminar-opcion')
    },
    data(){
        return {
            editar: -1,
            model:{
                singular:null,
                plural:null,
                genero:null,
            },
            options: [
                {
                    value:  1,
                    label: 'El'
                },
                {
                    value:  2,
                    label: 'La'
                }
            ],
            input1: '',
            listado:[],
            id_eliminar:null
        }
    },
    mounted(){
        this.ListarTipos()
    },
    methods: {
        async editarTipo(valid,row){
            try {
                if(valid){
                    this.editar=-1
                    const {data} = await TipoTienda.PutEditar(row)
                    this.notificacion('', data.mensaje, 'success')
                    this.ListarTipos()
                }
            } catch (e){
                this.error_catch(e)
            }

        },
        async ListarTipos(){
            try {
                const {data} = await TipoTienda.GetTipos()
                this.listado = data
            } catch (e){
                this.error_catch(e)
            }
        },
        async crearRegistro(valid){
            try {
                if(valid){
                    const {data} = await TipoTienda.PostCrear(this.model)
                    this.notificacion('', data.mensaje, 'success')
                    this.limpiar()
                    this.ListarTipos()
                    this.$refs.validacion.reset();

                }
            } catch (e){
                this.error_catch(e)
            }
        },
        eliminar(data){
            if(data && data.tiendas_count > 0){
                this.$refs.modalEliminarOpcion.toggle(data)
            }else{
                this.id_eliminar = data.id
                this.$refs.modalEliminar.toggle()
            }
        },
        async EliminarRegistro(){
            try {
                const {data} = await TipoTienda.Delete(this.id_eliminar)
                this.notificacion('', data.mensaje, 'success')
                this.ListarTipos()
                this.$refs.modalEliminar.toggle()
            } catch (e){
                this.error_catch(e)
            }
        },
        limpiar(){
            this.model.singular = null
            this.model.plural = null
        }
    }
}
</script>

<style lang="css" scoped>
</style>
