import { render, staticRenderFns } from "./tipoTiendas.vue?vue&type=template&id=6069fea4&scoped=true"
import script from "./tipoTiendas.vue?vue&type=script&lang=js"
export * from "./tipoTiendas.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6069fea4",
  null
  
)

export default component.exports