<template>
    <section class="pt-3">
        <div class="d-flex mx-0">
            <div class="bg-white px-0 mr-2 pt-2" style="border-radius:12px 12px 0px 0px;width:437px;">
                <div class="row mx-0 pt-2 border-bottom">
                    <div
                    v-for="(tab, t) in tabs"
                    :key="t"
                    class="cr-pointer"
                    :class="`col text-center ${tipoPanel == tab.tipo ? 'component-active' : 'text-general2'}`"
                    @click="onClickTab(tab)"
                    >
                        {{ tab.titulo }}
                    </div>
                </div>
                <div class="custom-scroll overflow-auto" style="height:calc(100vh - 187px)">
                    <component :is="panelActivo" />
                </div>
            </div>
            <div class="px-0 mx-2" style="width:calc(100vw - 550px)">
                <div class="custom-scroll overflow-auto" style="height:calc(100vh - 145px)">
                    <component :is="componenteActivo" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
export default {
    beforeRouteLeave(to, from, next){
        if (to.name != 'admin.publicidad.mensaje-notificacion.campana'){
            this.limpiarState()
        }
        next()
    },
    components: {
        estadisticas: () => import('./estadisticas'),
        estadisticasPanel: () => import('./estadisticasPanel'),
        perfil: () => import('./perfil'),
        perfilPanel: () => import('./perfilPanel')
    },
    data(){
        return{
            componenteActivo: 'perfil',
            panelActivo: 'perfilPanel',
            tipoPanel: 1,
            tabs: [
                {
                    titulo: 'Clientes',
                    componentePanel: 'perfilPanel',
                    componente: 'perfil',
                    tipo: 1
                },
                {
                    titulo: 'Leechero',
                    componentePanel: 'perfilPanel',
                    componente: 'perfil',
                    tipo: 2
                },
                {
                    titulo: 'Estadísticas',
                    componentePanel: 'estadisticasPanel',
                    componente: 'estadisticas',
                    tipo: 3
                }
            ]
        }
    },
    mounted(){
        let { tipo = 1} = this.$route.query
        this.tabs[0].titulo = this.$config.cliente
        this.tabs[1].titulo = this.$config.vendedor
        let tab = this.tabs.find(el => el.tipo == tipo)
        this.cambiarComponente(tab.componente)
        this.cambiarPanel(tab.componentePanel, tab.tipo)
    },
    methods: {
        ...mapActions({
            listarCarpetas: 'publicidad/notificaciones/listarCarpetas',
        }),
        ...mapMutations({
            setTipo: 'publicidad/notificaciones/setTipo',
            limpiarState: 'publicidad/notificaciones/limpiarState',
        }),
        onClickTab(tab){
            this.cambiarComponente(tab.componente)
            this.cambiarPanel(tab.componentePanel, tab.tipo)
            this.$router.push({name: 'admin.publicidad.mensajes-notificacion', query: { tipo: tab.tipo }})
        },
        cambiarComponente(value){
            this.componenteActivo = value;
        },
        async cambiarPanel(value, type){
            this.limpiarState()
            if ([1, 2].includes(type)){
                this.setTipo(type)
                await this.listarCarpetas()
            }
            this.panelActivo = value;
            this.tipoPanel = type
        },
        activo(type){
            switch (type){
            case 1:
                return 'component-active'
            case 2:
                return 'component-active'
            case 3:
                return 'component-active'
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.component-active{
    border-bottom: 2px solid var(--text-general) !important;
    color: var(--text-general) !important;
}
</style>
