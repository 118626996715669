<template>
    <section>
        <div class="row mx-0 my-1 align-items-center" style="height:50px;">
            <div class="col d-flex px-0 h-100 border-bottom">
                <div class="col-auto d-middle-center cr-pointer" :class="`${cargando ? '':'disabled-manual'} ${destinoSeleccionado === 1? 'border-b-red text-general-red':''}`" style="width:170px;" @click="cambiarDestino(1)">
                    Cedis
                </div>
                <div class="col-auto d-middle-center cr-pointer" :class="`${cargando ? '':'disabled-manual'} ${destinoSeleccionado === 2? 'border-b-red text-general-red':''}`" style="width:170px;" @click="cambiarDestino(2)">
                    {{$config.vendedor}}
                </div>
            </div>
            <div class="col-auto ml-auto">
                <el-tooltip placement="bottom" content="Descargar catálogo" effect="light">
                    <div class="bg-general f-14 text-white br-8 p-2 cr-pointer d-middle" style="height:32px;" @click="descargar()">
                        Descargar catálogo
                    </div>
                </el-tooltip>
            </div>
        </div>
        <div class="custom-scroll  pb-3 position-relative" :class="cargando? 'overflow-auto' : 'overflow-hidden' " style="height:calc(100vh - 188px);">
            <cargando v-if="!cargando" />
            <div class="row mx-0 mt-3 pl-3">
                <div class="col-4 bg-white br-12 pt-4 pb-3 mr-2">
                    <p class="text-general mb-3 px-3 f-17 f-600">Portada</p>
                    <div class="position-relative">
                        <slim-cropper ref="crop_portada" :options="slimOptions_portada" class="border cr-pointer position-relative slim_portada" style="height:228px;background:#F8F9FF;width:251px;" />
                        <p class="position-absolute text-muted2 f-20" style="bottom:5px;right:15px;">1:1</p>
                    </div>
                </div>
                <div class="col">
                    <div class="row mx-0 br-12 bg-white py-4 px-2">
                        <div class="col-12 text-general f-17 f-600 mb-3">
                            Header
                        </div>
                        <div class="col d-middle">
                            <div class="position-relative">
                                <slim-cropper ref="crop_header" :options="slimOptions_header" class="border cr-pointer slim_header" style="height:65px;width:calc(50vw - 45px);background:#F8F9FF;" />
                                <p class="position-absolute text-muted2 f-20" style="top:5px;right:15px;">20:2</p>
                            </div>
                        </div>
                    </div>
                    <div class="row mx-0 mt-4 bg-white br-12 py-4">
                        <div class="col-12 text-general f-17 f-600 mb-4">
                            Footer
                        </div>
                        <div class="col d-middle">
                            <div class="position-relative">
                                <slim-cropper ref="crop_footer" :options="slimOptions_footer" class="border cr-pointer slim_footer" style="height:35px;width:calc(50vw - 45px);background:#F8F9FF;" />
                                <p class="position-absolute text-muted2 f-20" style="top:-1px;right:15px;">20:1</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mx-0 px-3 my-3">
                <div class="col-12 bg-white pb-3 br-12">
                    <div class="row mx-0">
                        <div class="col-6 px-2 d-flex flex-column">
                            <p class="text-general f-18 f-600 mt-3">
                                Publicidad pequeña
                            </p>
                            <div class="d-flex my-4 text-general">
                                Repetir publicidad dentro del catálogo
                                <el-switch v-model="publicidad_activa" class="mx-2 switch-red" @change="evento(publicidad_activa,'publicidad_activa',false)" />
                            </div>
                            <div v-show="publicidad_activa">
                                <p class="text-general">
                                    Frecuencia de la publicidad <i class="icon-info-circled-alt text-gr-general f-18" />
                                </p>
                                <div class="row mx-0 mb-4 text-general">
                                    <div class="col-auto pl-0 f-15 d-middle">
                                        Mostrar Cada
                                    </div>
                                    <div class="col-2">
                                        <ValidationProvider v-slot="{errors, valid}" rules="integer|max_value:100" name="líneas">
                                            <el-input v-model="publicidad_repeticion" class="w-100" @change="evento(publicidad_repeticion,'publicidad_repeticion',!valid)" />
                                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-auto f-15 d-middle">
                                        Líneas
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <p class="text-general f-18 f-600 mt-3 mb-4">
                                Imagen
                            </p>
                            <div class="position-relative">
                                <slim-cropper ref="crop_publicidad" :options="slimOptions_peque_publicidad" class="border cr-pointer slim_peque_publicidad" style="height:131px;width:450px;background:#F8F9FF;" />
                                <p class="position-absolute text-muted2 f-20" style="top:3px;left:404px;">4:20</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mx-0 mx-3 bg-white px-3 pb-3 br-12">
                <div class="col-6 px-2 d-flex flex-column">
                    <p class="f-18 f-600 text-general my-4">
                        Publicidad página completa
                    </p>
                    <div class="d-flex my-4 text-general">
                        Repetir publicidad dentro del catálogo
                        <el-switch v-model="pagina_activa" class="mx-2 switch-red" @change="evento(pagina_activa,'pagina_activa',false)" />
                    </div>
                    <div v-show="pagina_activa">
                        <p class="mt-3 text-general">
                            Frecuencia de la publicidad
                        </p>
                        <div class="row mx-0 mb-4 text-general">
                            <div class="col-auto pl-0 f-15 d-middle">
                                Mostrar Cada
                            </div>
                            <div class="col-2">
                                <ValidationProvider v-slot="{errors, valid}" rules="integer|max_value:100" name="páginas">
                                    <el-input v-model="pagina_repeticion" class="w-100" @change="evento(pagina_repeticion,'pagina_repeticion',!valid)" />
                                    <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                </ValidationProvider>
                            </div>
                            <div class="col-auto f-15 d-middle">
                                Páginas
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <p class="text-general f-18 f-600 my-4">
                        Imagen
                    </p>
                    <div class="position-relative d-middle">
                        <slim-cropper ref="crop_pagina" :options="slimOptions_gran_publicidad" class="border cr-pointer slip_gran_publicidad" style="height:400px;width:290px;background:#F8F9FF;" />
                        <p class="position-absolute text-muted2 f-20" style="top:3px;left:226px;">2:3</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal-descargar-catalogo ref="modalDescargarCatalogo" />
    </section>
</template>

<script>
import Catalogo from "~/services/configurar/admin/catalogoProductos"
import Aws from '~/services/aws'

export default {
    components: {
        modalDescargarCatalogo: () => import('./partials/modalDescargarCatalogo')
    },
    data(){
        /**
         * Esto se usa para que guarde el this de vue para usarlo en el slim
         */
        let vm = this
        return {
            slimOptions_portada: {
                ratio: '1:1',
                label:'Subir imagen',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
                willRemove: function(a, remove){
                    vm.evento(a.output.image,'portada',vm.firstTime1,true)
                    vm.firstTime1 = false
                    remove()
                },
                didSave: function(a){
                    vm.evento(a.output.image,'portada',vm.firstTime1,true)
                    vm.firstTime1 = false
                }
            },
            slimOptions_header: {
                ratio: '30:2',
                label:'Subir imagen',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
                willRemove: function(data, remove){
                    vm.evento(data.output.image,'header',vm.firstTime2,true)
                    vm.firstTime2 = false
                    remove()
                },
                /* didRemove: function(a){
                    if(vm.changed)return
                    vm.evento(a.output.image,'header',vm.firstTime2,true)
                    vm.firstTime2 = false
                }, */
                didSave: function(a){
                    vm.evento(a.output.image,'header',vm.firstTime2,true)
                    vm.firstTime2 = false
                }
            },
            slimOptions_footer: {
                ratio: '20:1',
                label:'Subir imagen',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
                willRemove: function(a, remove){
                    vm.evento(a.output.image,'footer',vm.firstTime3,true)
                    vm.firstTime3 = false
                    remove()
                },
                didSave: function(a){
                    vm.evento(a.output.image,'footer',vm.firstTime3,true)
                    vm.firstTime3 = false
                }
            },
            slimOptions_peque_publicidad: {
                ratio: '30:4',  
                label:'Subir imagen',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
                willRemove: function(a, remove){
                    vm.evento(a.output.image,'publicidad',vm.firstTime4,true)
                    vm.firstTime4 = false
                    remove()
                },
                didSave: function(a){
                    vm.evento(a.output.image,'publicidad',vm.firstTime4,true)
                    vm.firstTime4 = false
                }
            },
            slimOptions_gran_publicidad: {
                ratio: '2:3',
                label:'Subir imagen',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
                willRemove: function(a, remove){
                    vm.evento(a.output.image,'pagina',vm.firstTime5,true, 1080)
                    vm.firstTime5 = false
                    remove()
                },
                didSave: function(a){
                    vm.evento(a.output.image,'pagina',vm.firstTime5,true, 1080)
                    vm.firstTime5 = false
                }
            },
            publicidad_activa: true,
            pagina_activa: true,
            firstTime1: true,
            firstTime2: true,
            firstTime3: true,
            firstTime4: true,
            firstTime5: true,
            publicidad_repeticion: '',
            pagina_repeticion: '',
            destinoSeleccionado: 1,
            changed: false,
            cargando: true
        }
    },
    watch: {
        async destinoSeleccionado(){
            this.changed = true
            this.limpiar()
            await this.get_catalogo_productos()
        }
    },
    mounted(){
        this.get_catalogo_productos()
    },
    methods: {
        async get_catalogo_productos(){
            try {
                this.cargando = false

                const { data } = await Catalogo.get_catalogo_productos(this.destinoSeleccionado)
                this.setCroppperImage(data.parametros.portada, 'crop_portada', 'firstTime1')
                this.setCroppperImage(data.parametros.header, 'crop_header', 'firstTime2')
                this.setCroppperImage(data.parametros.footer, 'crop_footer', 'firstTime3')
                this.setCroppperImage(data.parametros.publicidad, 'crop_publicidad', 'firstTime4')
                this.setCroppperImage(data.parametros.pagina, 'crop_pagina', 'firstTime5')
                this.publicidad_activa = data.parametros.publicidad_activa == 1 ? true : false
                this.pagina_activa = data.parametros.pagina_activa == 1 ? true : false
                this.publicidad_repeticion = data.parametros.publicidad_repeticion
                this.pagina_repeticion = data.parametros.pagina_repeticion
            } catch (e){
                this.error_catch(e)
            }finally{
                setTimeout(() => {
                    this.cargando = true
                }, 2000);
            }
        },
        setCroppperImage(data, cropper, variable){
            if (data !== null){
                this.$refs[cropper].set_image(`${data}`)
            } else {
                this.$refs[cropper].instanciaCrop.remove()
                this[variable] = false
            }
        },
        cambiarDestino(val){
            this.destinoSeleccionado = val
        },
        async evento(val,campo,valid,img = false, rel = 0){
            try {
                if (valid) return
                if(parseInt(this.publicidad_repeticion) === 0)return this.notificacion('Alerta','Frecuencia de la publicidad no pueder ser 0','warning');
                if(parseInt(this.pagina_repeticion) === 0)return this.notificacion('Alerta','Frecuencia de la publicidad no pueder ser 0','warning');
                let datos = {
                    img,
                    campo,
                    rel,
                    texto: campo == 'publicidad_activa' || campo == 'pagina_activa'  ? val ? 1 : 0 : val,
                    tipo: this.destinoSeleccionado
                }
                const {data} = await Catalogo.crear_catalogo_productos(datos)
                //this.$validar(data);
                if (datos.campo == 'publicidad_activa' && datos.texto == 0 ){
                    this.publicidad_repeticion = 0
                }

                if (datos.campo == 'pagina_activa' && datos.texto == 0 ){
                    this.pagina_repeticion = 0
                }
                this.notificacion('', data.mensaje, 'success')
            } catch (e){
                this.error_catch(e)
            }
        },
        cambiarLogo(valor){
            this.crop_modal = valor;
            this.$refs.CambiarLogo.toggle(valor);
        },
        limpiar(){
            this.firstTime1 = true
            this.firstTime2 = true
            this.firstTime3 = true
            this.firstTime4 = true
            this.firstTime5 = true
        },
        descargar(){
            this.$refs.modalDescargarCatalogo.toggle();
        }
    }
}
</script>

<style lang="scss" scoped>
.br-12{
    border-radius: 12px !important;
}
@media (max-width: 1146px){
    .slim_header{
        width: calc(50vw - 109px) !important;
    }
    .slim_footer{
        width: calc(50vw - 109px) !important;
    }

    .slim_peque_publicidad{
        width: calc(50vw - 149px) !important;
    }
}
.disabled-manual{
    pointer-events: none !important;
    opacity: 0.5;
}
</style>
