import axios from 'axios'

const clasificacionsTipos = {
    getCalificacionesDrivers: () => axios.get(`calificaciones-driver/driver`),
    crearCalificacionDriver: datos =>
        axios.post(`calificaciones-driver/driver`, datos),
    editarCalificacionDriver: datos =>
        axios.put(`calificaciones-driver/driver`, datos),
    eliminarCalificacionDriver: id =>
        axios.delete(`calificaciones-driver/${id}/driver`),
    actualizarPosicionCalificacionDriver: datos =>
        axios.post("calificaciones-driver/posicion-driver", datos),
    cambiarEstadoCalificacionDriver: datos =>
        axios.post(`calificaciones-driver/estado-driver`, datos)
};
export default clasificacionsTipos
