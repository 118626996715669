<template>
    <section>
        <div class="row mx-0 pl-5 border-bottom align-items-center" style="height:50px;">
            <div class="col-auto px-1 text-general f-600 f-18">
                Estados Cargamos Routing
            </div>
        </div>
        <div class="custom-scroll overflow-auto pt-4 px-4" style="height:calc(100vh - 152px)">
            <div class="row mx-0 align-items-center">
                <div class="col-auto px-0 text-general f-15">
                    Crea los estados para los pedidos(tiros) que manejan los drivers de cargamos
                </div>
                <div class="col-auto px-2 ml-auto">
                    <el-tooltip placement="bottom" content="Crear estado" effect="light">
                        <div class="btn-agregar d-middle-center text-white cr-pointer" @click="crear">
                            <i class="icon-plus" />
                        </div>
                    </el-tooltip>
                </div>
            </div>
            <div class="row mx-0 py-3" />
            <p class="text-general f-15 f-500 mb-3">Lista de estados</p>
            <div v-if="estadosTipos.length" class="overflow-auto custom-scroll" style="height:calc(100% - 135px);">
                <draggable :list="estadosTipos" handle=".handle" @change="actualizarPosicionEstadoTipo">
                    <div v-for="(lista, l) in estadosTipos" :key="l" class="row mx-0 mb-3 align-items-center">
                        <i class="icon-drag f-20 cr-pointer handle" />
                        <div class="col-5">
                            <el-input v-model="lista.nombre" size="small" disabled class="w-100 br-6" />
                        </div>
                        <div class="col-2 px-0 d-middle">
                            <el-input v-model="lista.cargamos_id" size="small" disabled class="w-100 br-6" />
                            <el-tooltip placement="bottom" :content="lista.descripcion" effect="light">
                                <i class="icon-info-circled-alt text-general f-18" />
                            </el-tooltip>
                        </div>

                        <div class="col-2">
                            <p v-if="lista.tipo === 1" class="text-general">Proceso</p>
                            <p v-if="lista.tipo === 2" class="text-general">Finalizado con error</p>
                            <p v-if="lista.tipo === 3" class="text-general">Finalizado con éxito</p>
                        </div>

                        <div class="col-auto px-1">
                            <el-tooltip placement="bottom" content="Editar" effect="light">
                                <div class="btn-routing border br-8 d-middle-center text-general cr-pointer" @click="editar(lista)">
                                    <i class="icon-pencil-outline f-20" />
                                </div>
                            </el-tooltip>
                        </div>
                        <div class="col-auto px-1">
                            <el-tooltip placement="bottom" content="Eliminar" effect="light">
                                <div class="btn-routing border br-8 d-middle-center text-general cr-pointer" @click="eliminar(lista.id)">
                                    <i class="icon-delete-outline f-20" />
                                </div>
                            </el-tooltip>
                        </div>
                        <div class="col-auto">
                            <el-switch v-model="lista.estado" active-color="#000000" inactive-color="#DBDBDB" @change="cambiarEstadoEstadoTipo(lista)" />
                        </div>
                    </div>
                </draggable>
            </div>
            <sinDatos v-else icon="list" mensaje="No se ha creado ningún criterio" />
        </div>
        <!-- Partials -->
        <modal ref="modalCrearEstado" titulo="Crear estado cargamos" no-aceptar adicional="Crear" @adicional="crearEstadoTipo">
            <ValidationObserver ref="validacion1">
                <div class="row mx-0 justify-center my-3 justify-center">
                    <div class="col-10">
                        <ValidationProvider v-slot="{errors}" vid="nombre" rules="required|max:30" name="nombre estado">
                            <p class="text-general pl-3">Nombre del estado</p>
                            <el-input v-model="model.nombre" placeholder="Nombre estado" class="w-100 br-6">
                                <i v-if="errors.length" slot="suffix" class="icon-attention-alt text-danger el-input__icon f-17" />
                            </el-input>
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row mx-0 justify-center mt-3">
                    <div class="col-10">
                        <ValidationProvider v-slot="{errors}" vid="cargamos_id" rules="required|max:10|numeric" name="id cargamos">
                            <p class="text-general pl-3">Id cargamos</p>
                            <el-input v-model="model.cargamos_id" placeholder="Id" class="w-100 br-6" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row mx-0 justify-center mt-3">
                    <div class="col-10">
                        <ValidationProvider v-slot="{errors}" vid="descripcion" rules="required|max:300" name="descripción">
                            <p class="text-general pl-3">Descripción</p>
                            <el-input v-model="model.descripcion" class="w-100 br-6" placeholder="Descripción del estado" type="textarea" :rows="4" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row mx-0 justify-center mt-3">
                    <div class="col-11">
                        <ValidationProvider v-slot="{errors}" vid="tipo" rules="required" name="tipo">
                            <p class="text-general pl-3">Tipo de estado</p>
                            <el-radio-group v-model="model.tipo">
                                <el-radio :label="1">
                                    <small>Proceso</small>
                                </el-radio>
                                <el-radio :label="3">
                                    <small>Finalizado con éxito</small>
                                </el-radio>
                                <el-radio :label="2">
                                    <small>Finalizado con error</small>
                                </el-radio>
                            </el-radio-group>
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                </div>
            </ValidationObserver>
        </modal>
        <modal ref="modalEditarEstado" titulo="Editar estado cargamos" no-aceptar adicional="Editar" @adicional="editarEstadoTipo">
            <ValidationObserver ref="validacion">
                <div class="row mx-0 justify-center my-3 justify-center">
                    <div class="col-10">
                        <ValidationProvider v-slot="{errors}" vid="nombre" rules="required|max:30" name="nombre estado">
                            <p class="text-general pl-3">Nombre del estado</p>
                            <el-input v-model="model.nombre" placeholder="Nombre estado" class="w-100 br-6">
                                <i v-if="errors.length" slot="suffix" class="icon-attention-alt text-danger el-input__icon f-17" />
                            </el-input>
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row mx-0 justify-center mt-3">
                    <div class="col-10">
                        <ValidationProvider v-slot="{errors}" vid="id" rules="required|max:10|numeric" name="id cargamos">
                            <p class="text-general pl-3">Id cargamos</p>
                            <el-input v-model="model.cargamos_id" placeholder="Id" class="w-100 br-6" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row mx-0 justify-center mt-3">
                    <div class="col-10">
                        <ValidationProvider v-slot="{errors}" vid="descripcion" rules="required|max:300" name="descripción">
                            <p class="text-general pl-3">Descripción</p>
                            <el-input v-model="model.descripcion" class="w-100 br-6" placeholder="Descripción del estado" type="textarea" :rows="4" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row mx-0 justify-center mt-3">
                    <div class="col-11">
                        <ValidationProvider v-slot="{errors}" vid="tipo" rules="required" name="tipo">
                            <p class="text-general pl-3">Tipo de estado</p>
                            <el-radio-group v-model="model.tipo">
                                <el-radio :label="1">
                                    <small>Proceso</small>
                                </el-radio>
                                <el-radio :label="3">
                                    <small>Finalizado con éxito</small>
                                </el-radio>
                                <el-radio :label="2">
                                    <small>Finalizado con error</small>
                                </el-radio>
                            </el-radio-group>
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                </div>
            </ValidationObserver>
        </modal>
        <modal-eliminar ref="modalEliminarEstado" titulo="Eliminar Estado" mensaje="¿Desea eliminar este estado?" @eliminar="eliminarEstadoTipo" />
    </section>
</template>

<script>
import EstadosTipos from '~/services/configurar/admin/estadosTipos'
export default {
    data(){
        return {
            model:{
                id: null,
                nombre: null,
                cargamos_id: null,
                descripcion: null,
                tipo: 1,
            },
            id_eliminar:null,
            estadosTipos: []
        }
    },
    mounted(){
        this.getEstadosTipos()
    },
    methods: {
        crear(){
            this.limpiar();
            this.$refs.modalCrearEstado.toggle();
        },
        eliminar(id){
            if(id){
                this.id_eliminar = id
            }
            this.$refs.modalEliminarEstado.toggle();
        },
        async getEstadosTipos(){
            try {
                const {data} = await EstadosTipos.getEstadosTipos()
                data.forEach(element => {
                    element.estado = !!element.estado
                });
                this.estadosTipos = data
            } catch (e){
                this.error_catch(e)
            }
        },
        async crearEstadoTipo(){
            try {
                let valid = await this.$refs.validacion.validate()
                if(!valid) return
                const {data} = await EstadosTipos.crearEstadoTipo(this.model)
                this.notificacion('', data.mensaje, 'success')
                this.$refs.modalCrearEstado.toggle();
                this.limpiar()
                this.getEstadosTipos()
            } catch (e){
                this.error_catch(e, 'validacion1')
            }
        },
        editar(model){
            this.model.id = model.id
            this.model.nombre = model.nombre
            this.model.cargamos_id = model.cargamos_id
            this.model.descripcion = model.descripcion
            this.model.tipo = model.tipo
            this.$refs.modalEditarEstado.toggle();
        },
        async editarEstadoTipo(){
            try {
                let valid = await this.$refs.validacion.validate()
                if(!valid) return
                const {data} = await EstadosTipos.editarEstadoTipo(this.model)
                this.notificacion('', data.mensaje, 'success')
                this.$refs.modalEditarEstado.toggle();
                this.limpiar()
                this.getEstadosTipos()
            } catch (e){
                this.error_catch(e, 'validacion')
            }
        },
        async eliminarEstadoTipo(){
            try {
                const {data} = await EstadosTipos.eliminarEstadoTipo(this.id_eliminar)
                this.notificacion('', data.mensaje, 'success')
                this.getEstadosTipos()
                this.$refs.modalEliminarEstado.toggle()
            } catch (e){
                this.error_catch(e)
            }
        },
        async actualizarPosicionEstadoTipo(event){
            try {
                const datos = {
                    antiguaPosicion: event.moved.oldIndex + 1,
                    nuevaPosicion: event.moved.newIndex + 1,
                    id: event.moved.element.id,
                }
                const { data } = await EstadosTipos.actualizarPosicionEstadoTipo(datos)
                this.notificacion('', data.mensaje, 'success')
                this.getEstadosTipos()

            } catch (error){
                this.error_catch(error)
            }
        },
        async cambiarEstadoEstadoTipo(row){
            try {
                let datos={
                    id:row.id,
                    estado:row.estado
                }
                const {data} = await EstadosTipos.cambiarEstadoEstadoTipo(datos)
                this.notificacion('', data.mensaje, 'success')
                this.getEstadosTipos()
            } catch (e){
                this.error_catch(e)
            }
        },
        limpiar(){
            this.model = {
                nombre: null,
                cargamos_id: null,
                descripcion: null,
                tipo: 1
            }
            this.$refs.validacion.reset();
        }
    }
}
</script>
<style lang="scss" scoped>
.btn-agregar{
    width: 32px;
    height: 32px;
    border-radius: 8px;
    background-color: var(--color-general);
}
.btn-guardar{
    width: 44px;
    height: 44px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 12px;
    background-color: #F5F5F5;
    &:hover{
        background-color: var(--color-general2) !important;
        color: #ffffff !important;
    }
}
.btn-routing{
    width: 32px;
    height: 32px;
    background-image: linear-gradient(to bottom, #FFFFFF, #F3F5F7);
}
</style>
